import * as React from "react"
import { useInView } from "react-intersection-observer"
import { isMobile } from "react-device-detect"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/hero"
import CaseStudies from "../components/caseStudies/caseStudies"

const IndexPage = () => {
  let rootMargin = isMobile ? "226px" : "340px"
  let [ref, inView] = useInView({ rootMargin: rootMargin, initialInView: true })
  return (
    <Layout headerColor="white" inView={inView}>
      <Seo />
      <Hero ref={ref} />
      <CaseStudies />
    </Layout>
  )
}

export default IndexPage
