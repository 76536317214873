import React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSpring, animated } from "react-spring"
import { FaArrowDown } from "react-icons/fa"
import springObj from "../../utils/springObj"

const HeroSection = styled.section`
  height: 100vh;

  background-color: var(--primary-orange);

  a,
  a:link,
  a:visited {
    color: white;
  }
`

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: 630px) {
    padding-left: 2em;
  }
  @media only screen and (max-width: 350px) {
    padding-left: 0;
  }
`

const HeroText = styled.h2`
  font-size: 4em;
  text-align: left;
  color: white;

  @media only screen and (max-width: 865px) {
    font-size: 3em;
  }

  @media only screen and (max-width: 425px) {
    font-size: 2em;
  }
`

const AboutLink = styled.h3`
  margin-top: 1em;

  a:hover,
  a:focus,
  a:active {
    color: var(--orange-hover-2);
  }

  @media only screen and (max-width: 425px) {
    font-size: 1em;
  }
`

const CaseStudiesContainer = styled.div`
  transform: translateY(-10em);
  display: block;
  /* padding-top: 15em; */
  align-self: flex-start;

  @media only screen and (max-width: 860px) {
    transform: translateY(-5em);
  }
`

const CaseStudiesLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AnimatedText = animated(HeroText)
const AnimatedAboutLink = animated(AboutLink)

const Hero = (__, ref) => {
  return (
    <HeroSection>
      <HeroContainer>
        <div ref={ref}>
          <AnimatedText
            style={useSpring({
              ...springObj,
              delay: 400,
            })}
          >
            Hi! I'm James.
          </AnimatedText>
          <AnimatedText
            style={useSpring({
              ...springObj,
              delay: 500,
            })}
          >
            I'm a graphic designer.
          </AnimatedText>

          <AnimatedAboutLink style={useSpring({ ...springObj, delay: 700 })}>
            <AniLink cover direction="left" bg="#FFF" to="/about">
              More about me
            </AniLink>
          </AnimatedAboutLink>
        </div>
      </HeroContainer>
      <CaseStudiesContainer>
        <CaseStudiesLink>
          <AnchorLink to="/#projects">
            <animated.div
              class="arrow bounce"
              style={useSpring({
                to: { opacity: 1 },
                from: { opacity: 0 },
                config: { duration: 500 },
                delay: 1200,
              })}
            >
              <FaArrowDown />
            </animated.div>
          </AnchorLink>
        </CaseStudiesLink>
      </CaseStudiesContainer>
    </HeroSection>
  )
}

export default React.forwardRef(Hero)
