import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import CaseStudy from "./caseStudy"

const CaseStudiesSection = styled.section`
  padding-top: 4em;
  padding-bottom: 4em;
  max-width: 1400px;
  margin: 0 auto;

  a:link,
  a:visited,
  a:active,
  a:hover {
    text-decoration: none;
    color: var(--primary-orange);
  }

  @media only screen and (max-width: 960px) {
    padding-left: 2em;
    padding-right: 2em;
  }
`

const Title = styled.h2`
  font-size: 3em;
  color: var(--primary-orange);
  margin-bottom: 1em;
`

const CaseStudies = () => {
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: frontmatter___order }) {
        nodes {
          frontmatter {
            title
            desc
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <CaseStudiesSection id="projects">
      <Title>Projects</Title>
      {nodes.map(({ frontmatter, fields }, index) => {
        const { title, desc, img } = frontmatter
        const image = getImage(img)
        return index % 2 === 0 ? (
          <AniLink paintDrip hex="#F55742" to={fields.slug} key={index}>
            <CaseStudy
              orientation="left"
              title={title}
              desc={desc}
              img={image}
            />
          </AniLink>
        ) : (
          <AniLink paintDrip hex="#F55742" to={fields.slug} key={index}>
            <CaseStudy
              orientation="right"
              title={title}
              desc={desc}
              img={image}
            />
          </AniLink>
        )
      })}
    </CaseStudiesSection>
  )
}

export default CaseStudies
