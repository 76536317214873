import React from "react"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

const CaseStudyDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, min-content);
  margin-bottom: 4em;

  @media only screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`

const CaseStudyImage = styled.div`
  grid-column: ${props => (props.orientation === "left" ? "1 / 5" : "4 / -1")};
  grid-row: 1 / -1;
`

const CaseStudyText = styled.div`
  grid-column: ${props => (props.orientation === "left" ? "4 / -1" : "1 / 5")};
  text-align: ${props => (props.orientation === "left" ? "left" : "right")};
  grid-row: 2 / 6;
  z-index: 1000;
  background-color: var(--transparent-white);
  padding: 1em;

  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  p {
    font-weight: 500;
  }

  @media only screen and (max-width: 960px) {
    grid-row: 2 / -1;
  }

  @media only screen and (max-width: 860px) {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`

const CaseStudy = ({ orientation, title, desc, img }) => {
  return (
    <CaseStudyDiv>
      <CaseStudyImage orientation={orientation}>
        <GatsbyImage image={img} alt={title} />
      </CaseStudyImage>
      <CaseStudyText orientation={orientation}>
        <h2>{title}</h2>
        <p>{desc}</p>
      </CaseStudyText>
    </CaseStudyDiv>
  )
}

export default CaseStudy
